import React from 'react';

import './style.scss';
import '../styles/review-styles.css';

import { FaStar } from 'react-icons/fa';

const Reviews = () => { 
    const reviewListArr = [
        {name: 'Jonathan Adams', imgUrl: 'https://lh3.googleusercontent.com/-tXCb-RmHV08/AAAAAAAAAAI/AAAAAAAAAAA/Ltfk3x2ngwE/s50-c-mo/photo.jpg', text:'Very nice guy he hooked me up and my friend up great price I will always come here to fix my phones and get phone cases would definitely recommend him Serg thank you'}, 
        {name:"Alli James", imgUrl:"https://lh3.googleusercontent.com/-T6BAUh0NS6Y/AAAAAAAAAAI/AAAAAAAAAAA/xPA1nOBXLxw/s50-c-mo/photo.jpg", text:"Excellent repairs, fast and friendly service. I would highly recommend choosing them for any phone repair and/or phone accessories that you need. Thank you for the great service!"}, 
        {name:"Lil Peep", imgUrl:"https://lh3.googleusercontent.com/-DGwm0uj-D9U/AAAAAAAAAAI/AAAAAAAAAAA/REsHMfWJp_M/s50-c-mo/photo.jpg", text:"I needed my phone screen fixed and it was fixed in less than 30 minutes and he replaced my camera free of cost. im very happy with this work :)"}, 
        {name:"Anass Zolgami", imgUrl:"https://lh3.googleusercontent.com/-RZXdxSfWOc4/AAAAAAAAAAI/AAAAAAAAAAA/e4Sy_T6Ypjw/s50-c-mo/photo.jpg", text:"Nice customer service. Quick repair"}]

    const removeFirstChar = (text) => {
        const newText = text.substring(1);
        return newText;
    }

    const setFirstChar = (text) => {
        const firstChar = text.charAt(0);
        return firstChar;
    }
    return (
        <div className='reviewsWrapper'>
            {
                reviewListArr.map((review) => {
                return (
                    <div className="reviewItemWrapper" key={review.name}>
                        <img className="reviewItemImg" src={review.imgUrl} alt="Client Avatar"/>
                        <p className="reviewItemImgName">{review.name}</p>
                        <div className="reviewItemStarWrapperMobile">
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                          </div>
                        <p className="reviewItemText">
                            <span className="reviewItemTextLargerSpan">"{setFirstChar(review.text)}</span>{removeFirstChar(review.text)}"
                        </p>
                        <div className="reviewItemStarWrapperDesktop">
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                            <FaStar className="reviewItemStar"/>
                          </div>
                    </div>
                )})
            }
        </div>
    )
}

export default Reviews;